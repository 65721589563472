import React from "react";
import resumeSVG from "../documents/resume.svg";

const Resume = ({ classicHeader, darkTheme }) => {
 
  return (
    <section
      id="resume"
      className={"section " + (darkTheme ? "bg-dark-1" : "")}
    >
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            Resume
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            {" "}
            CV
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}
       <div className="text-center mt-5">
        <img className="mw-100" src={resumeSVG} alt="resumeSVG"/>
       </div>
        <div className="text-center mt-5">
          <a
            className="btn btn-outline-secondary rounded-pill shadow-none"
            href="https://drive.google.com/file/d/1dtfOml5G6oK1Pj2OFDrWKAAFB3J0r5RZ/view"
            target="_blank"
            rel="noopener noreferrer"
            /* download="Paul Levitsky - Full-stack Developer - CV Resume" */
          >
            Download CV
            <span className="ms-1">
              <i className="fas fa-download" />
            </span>
          </a>
        </div>
      </div>
    </section>
  );
};

export default Resume;
