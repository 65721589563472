import React from "react";

import Slider from "react-slick";

const Certifications = ({ classicHeader, darkTheme }) => {
  const certs = [
    {
      name: "",
      position: "",
      src: "https://csb39af7c2f2658x433dxa4d.blob.core.windows.net/credentials/63cab7b85ae2c49b217d891b.svg",
      desc: "6 months online course Fullstack MERN (Mongo, Express, React, Node), Agile methodologies, PostgreSQL, Azure, Heroku, versioning (git / GitHub), devops, object oriented programming, data structure and algorithms",
      rating: 0,
      link: "https://benchmark.epicode.com/credentials/63cab7c05ae2c49b217d8937"  
    },
    {
      name: "",
      position: "",
      src: "https://images.credly.com/size/340x340/images/99becefb-f627-413c-8ad3-b52534e50037/image.png",
      desc: "Earners of the Unity Certified Associate: Game Developer badge possess a solid understanding of end-to-end game production using Unity, spanning key concepts in both technical art and programming. These earners have the core skills required to be confident iterating with prototypes, debugging, and solving programming challenges for successful game design and coding.",
      rating: 0,
      link: "https://www.credly.com/badges/892d366f-c12a-49a2-9449-8d018c7c3c20/public_url"      
    }
  ];

  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };
  return (
    <section
      id="certifications"
      className={"section " + (darkTheme ? "bg-dark-1" : "")}
    >
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            Certifications
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            {" "}
            Accreditations
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}

        <Slider {...settings}>
          {certs.length > 0 &&
            certs.map((value, index) => (
              <div className="item" key={index}>
                <div
                  className={
                    " rounded p-5 " + (darkTheme ? "bg-dark" : "bg-light")
                  }
                >
                  <div className="d-flex justify-content-center align-items-center mt-auto mb-4">
                    <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href={value.link}>
                    <img
                      className="img-fluid mw-100 border-none d-inline-block w-auto"
                      src={value.src}
                      alt=""
                      style={{height: "200px"}}
                    /></a>
                    <p className="ms-3 mb-0">
                      <strong
                        className={
                          "d-block fw-600 " +
                          (darkTheme ? "text-white" : "text-dark")
                        }
                      >
                        {value.name}
                      </strong>
                      <span className="text-muted fw-500">
                        {" "}
                        {value.position}{" "}
                      </span>
                    </p>
                  </div>
                  <p
                    className={
                      " fw-500 mb-4 " + (darkTheme ? "text-white" : "text-dark")
                    }
                  >
                    “{value.desc}”
                  </p>
                  <span className="text-2">
                    {[...Array(value.rating)].map((value, i) => (
                      <i className="fas fa-star text-warning" key={i} />
                    ))}
                  </span>
                </div>
              </div>
            ))}
        </Slider>
      </div>
    </section>
  );
};

export default Certifications;
